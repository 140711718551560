import type { ValidationStatus } from "@app/design-system";
import { useCallback, useRef, useState } from "react";
import type { ControllerFieldState } from "react-hook-form";
import { postFileupload } from "../../.rest-hooks/file-upload";

export interface FileUploadResult {
  filename: string;
  s3Uri: string;
  url: string;
}

export interface FileUploadRequest {
  id: string;
  upload: Promise<FileUploadResult | void>;
}

export interface FileUploadResponse {
  id: string;
  error: Error | undefined;
  payload: FileUploadResult | void;
}

export interface FileUploadState {
  hasFile: boolean;
  result: FileUploadResult | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

export type FileUploadFn = (file: File) => Promise<FileUploadResult>;

export type UseUploadFileResult = readonly [FileUploadFn, FileUploadState];

interface GetFileInputValidationStatusParams {
  fieldState: ControllerFieldState;
  uploadState: FileUploadState;
}

export const getFileInputValidationStatus = ({
  fieldState,
  uploadState,
}: GetFileInputValidationStatusParams): ValidationStatus | undefined => {
  if (uploadState.isError || fieldState.error) {
    return "error";
  }

  if (uploadState.isSuccess) {
    return "success";
  }

  return undefined;
};

export const useUploadFile = (): UseUploadFileResult => {
  const [uploadState, setUploadState] = useState<FileUploadState>({
    hasFile: false,
    result: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
  });

  const fileRef = useRef<File | null>(null);

  const uploadFile = useCallback<FileUploadFn>(
    async (file: File) => {
      if (
        fileRef.current === file &&
        uploadState.isSuccess &&
        uploadState.result
      ) {
        return { ...uploadState.result };
      }

      fileRef.current = file;

      setUploadState({
        hasFile: true,
        result: null,
        isError: false,
        isLoading: true,
        isSuccess: false,
      });

      try {
        const { data } = await postFileupload();

        if (!data.meta?.url || !data.meta?.s3Uri) {
          throw new Error("Could not start file upload");
        }

        const response = await fetch(data.meta?.url, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          method: "PUT",
          body: file,
        });
        if (!response.ok) {
          throw new Error(`Failed to upload file: ${file.name}`);
        }

        const result: FileUploadResult = {
          filename: file.name,
          s3Uri: data.meta.s3Uri,
          url: data.meta.url,
        };

        setUploadState({
          hasFile: true,
          result,
          isError: false,
          isLoading: false,
          isSuccess: true,
        });

        return result;
      } catch {
        setUploadState({
          hasFile: true,
          result: null,
          isError: true,
          isLoading: false,
          isSuccess: false,
        });

        throw new Error(`Error uploading file ${file.name}`);
      }
    },
    [uploadState.isSuccess, uploadState.result],
  );

  return [uploadFile, uploadState] as const;
};

export const handleFileUploadResults = async (uploads: FileUploadRequest[]) => {
  const results = await Promise.allSettled(
    uploads.map((request) => request.upload),
  );
  const allUploads: FileUploadResponse[] = results.map((result, index) => ({
    id: uploads[index].id,
    error: result.status === "rejected" ? (result.reason as Error) : undefined,
    payload: result.status === "fulfilled" ? result.value : undefined,
  }));

  const successfulUploads = allUploads.filter((upload) => !upload.error);
  const failedUploads = allUploads.filter((upload) => upload.error);

  return [successfulUploads, failedUploads] as const;
};
