import type mapboxgl from "mapbox-gl";
import type { IControl } from "mapbox-gl";

export class ZoomLevelControl implements IControl {
  private map?: mapboxgl.Map;

  private container?: HTMLDivElement;

  onAdd(map: mapboxgl.Map): HTMLDivElement {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className =
      "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-zoom";

    this.updateZoom();

    this.map.on("zoom", this.updateZoom.bind(this));

    return this.container;
  }

  onRemove(): void {
    if (this.container && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    this.map = undefined;
  }

  private updateZoom(): void {
    if (this.map && this.container) {
      this.container.textContent = `${this.map.getZoom().toFixed(2)}`;
    }
  }
}
