/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { PostFileupload200 } from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get S3 presigned upload URL
 */
export const postFileupload = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostFileupload200>> => {
  return axios.post(`/fileupload`, undefined, options);
};

export const getPostFileuploadMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postFileupload>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postFileupload>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postFileupload>>,
    void
  > = () => {
    return postFileupload(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFileuploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postFileupload>>
>;

export type PostFileuploadMutationError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get S3 presigned upload URL
 */
export const usePostFileupload = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postFileupload>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postFileupload>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getPostFileuploadMutationOptions(options);

  return useMutation(mutationOptions);
};
