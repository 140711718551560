import mapboxgl from "mapbox-gl";
import maplibregl from "maplibre-gl";
import type React from "react";
import { useEffect } from "react";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import ResetMapControl from "./ResetMapControl";
import { ZoomLevelControl } from "./ZoomLevelControl";

interface NavigationControlsProps {
  isMapRailEnabled?: boolean;
  isPredictionsActive?: boolean;
}

/**
 * Adds FullscreenControl and NavigationControl to the map.
 *
 * Note: Code duplication between Mapbox and MapLibre implementations is intentional here. Although the code is mostly
 * duplicated, combining the code would significantly increase the complexity and reduce type-safety.
 */
const NavigationControls: React.FC<NavigationControlsProps> = ({
  isMapRailEnabled,
  isPredictionsActive,
}) => {
  const { lib, map } = useUnsafeMapContext();
  const { options } = useDeveloperOptions();

  useEffect(() => {
    if (!map || lib !== "mapbox") return;

    const scaleControl = new mapboxgl.ScaleControl();
    const fullscreenControl = new mapboxgl.FullscreenControl();
    const navigationControl = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });
    const resetMapControl = new ResetMapControl({
      disabled: !!isPredictionsActive,
    });
    const zoomControl = new ZoomLevelControl();

    if (isMapRailEnabled) {
      map.addControl(scaleControl, "bottom-right");
    }

    if (options.isZoomControlEnabled) {
      map.addControl(zoomControl, "bottom-right");
    }

    map.addControl(fullscreenControl, "bottom-right");
    map.addControl(navigationControl, "bottom-right");
    map.addControl(geolocateControl, "bottom-right");

    if (isMapRailEnabled) {
      map.addControl(resetMapControl, "bottom-right");
    }

    return () => {
      if (isMapRailEnabled) {
        map.removeControl(scaleControl);
      }

      map.removeControl(fullscreenControl);
      map.removeControl(navigationControl);
      map.removeControl(geolocateControl);

      if (isMapRailEnabled) {
        map.removeControl(resetMapControl);
      }

      if (options.isZoomControlEnabled) {
        map.removeControl(zoomControl);
      }
    };
  }, [
    lib,
    map,
    isMapRailEnabled,
    isPredictionsActive,
    options.isZoomControlEnabled,
  ]);

  useEffect(() => {
    if (!map || lib !== "maplibre") return;

    const scaleControl = new maplibregl.ScaleControl({});
    const fullscreenControl = new maplibregl.FullscreenControl();
    const navigationControl = new maplibregl.NavigationControl({
      visualizePitch: true,
    });
    const geolocateControl = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      // @ts-expect-error Types are outdated, this is a valid option
      showUserHeading: true,
    });
    const resetMapControl = new ResetMapControl({
      disabled: !!isPredictionsActive,
    });

    if (isMapRailEnabled) {
      map.addControl(scaleControl, "bottom-right");
    }

    map.addControl(fullscreenControl, "bottom-right");
    map.addControl(navigationControl, "bottom-right");
    map.addControl(geolocateControl, "bottom-right");

    if (isMapRailEnabled) {
      map.addControl(resetMapControl, "bottom-right");
    }

    return () => {
      if (isMapRailEnabled) {
        map.removeControl(scaleControl);
      }

      map.removeControl(fullscreenControl);
      map.removeControl(navigationControl);
      map.removeControl(geolocateControl);

      if (isMapRailEnabled) {
        map.removeControl(resetMapControl);
      }
    };
  }, [lib, map, isMapRailEnabled, isPredictionsActive]);

  return null;
};

export default NavigationControls;
