import { bbox } from "@turf/bbox";
import type { Feature, FeatureCollection } from "geojson";
import chunk from "lodash/chunk";
import type { LngLatBoundsLike } from "mapbox-gl";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMouseLocation } from "../../../state/MouseProvider";
import useMapContext from "../Map/useMapContext";

interface GeojsonResult {
  geojson: Feature | FeatureCollection | undefined;
  id: string;
  isError: boolean;
}

interface FitToGeojsonContextValue {
  register: (id: string) => void;
  unregister: (id: string) => void;
  update: (result: GeojsonResult) => void;
}

const FitToGeojsonContext = createContext<FitToGeojsonContextValue | undefined>(
  undefined,
);

const ANIMATION_DURATION = 200;

interface FitToGeojsonProps {
  children?: React.ReactNode;
  contentPadding?: number;
  maxZoom?: number;
}

/**
 * Wrapped components that call `useFitToGeojson` will register themselves as
 * wanting to provide either Geojson or enter an error state. When all registered
 * wrapped children resolve, `FitToGeojson` will calculate the bounding box
 * of all provided Geojson and fit it into the map view.
 *
 */
const FitToGeojson = ({
  children,
  contentPadding = 100,
  maxZoom = 11,
}: FitToGeojsonProps) => {
  const map = useMapContext();
  const registeredLayers = useRef<string[]>([]);
  const [geojsonResults, setGeojsonResults] = useState<GeojsonResult[]>([]);
  const hasFitToGeojson = useRef<boolean>(false);

  const { setMouseLocation } = useMouseLocation();

  // Only fits to content the first time that all currently registered components
  // settle. If there are no registered components, nothing will happen.
  useEffect(() => {
    if (hasFitToGeojson.current || !registeredLayers.current.length) return;

    const allRegisteredLayersHaveResults = registeredLayers.current.every(
      (registeredId) =>
        geojsonResults.some((result) => result.id === registeredId),
    );

    if (!allRegisteredLayersHaveResults) return;

    const allResultsAreSettled = geojsonResults.every(
      (result) => result.geojson || result.isError,
    );

    if (!allResultsAreSettled) return;

    const successfulResults = geojsonResults.filter((result) => result.geojson);

    if (!successfulResults.length) return;

    const features = successfulResults
      .map((result) => {
        const { geojson } = result;
        if (!geojson) return [];
        if (geojson.type === "Feature") return geojson;
        return geojson.features;
      })
      .flat();

    const featuresBbox = bbox({
      type: "FeatureCollection",
      features,
    });

    const [minX, minY, maxX, maxY] = featuresBbox;

    const centerLngLat: LngLatObject = {
      lng: (minX + maxX) / 2,
      lat: (minY + maxY) / 2,
    };

    setMouseLocation(centerLngLat);

    const bboxAsLngLatBounds = chunk(featuresBbox, 2) as LngLatBoundsLike;

    map.fitBounds(
      // @ts-expect-error TS is getting confused when merging Mapbox and MapLibre overloads
      bboxAsLngLatBounds,
      {
        maxZoom,
        padding: contentPadding,
        duration: ANIMATION_DURATION,
      },
    );

    hasFitToGeojson.current = true;
  }, [contentPadding, geojsonResults, map, maxZoom, setMouseLocation]);

  const register = useCallback((id: string) => {
    if (!registeredLayers.current.includes(id)) {
      registeredLayers.current = [...registeredLayers.current, id];
      hasFitToGeojson.current = false;
    }
  }, []);

  const unregister = useCallback((id: string) => {
    registeredLayers.current = [
      ...registeredLayers.current.filter((registeredId) => registeredId !== id),
    ];
    setGeojsonResults((prev) => prev.filter((result) => result.id !== id));
  }, []);

  const update = useCallback((result: GeojsonResult) => {
    setGeojsonResults((prev) => {
      if (
        hasFitToGeojson.current &&
        prev.some((prevResult) => prevResult.id === result.id)
      ) {
        return prev;
      }

      if (prev.some((prevResult) => prevResult.id === result.id)) {
        return prev.map((prevResult) =>
          prevResult.id === result.id ? result : prevResult,
        );
      }

      return [...prev, { ...result }];
    });
  }, []);

  const fitToGeojsonProviderValue = useMemo(
    () => ({ register, unregister, update }),
    [register, unregister, update],
  );

  return (
    <FitToGeojsonContext.Provider value={fitToGeojsonProviderValue}>
      {children}
    </FitToGeojsonContext.Provider>
  );
};

export default FitToGeojson;

/**
 * Used inside a component that wants to make itself available to `FitToGeojson`
 * parent component. Upon mount, the component will immediately register itself
 * onto FitToGeojson inside a `useLayoutEffect` so that `FitToGeojson`
 * knows straight away what child components are planning to resolve with
 * either `geojson` or an `isError` flag.
 *
 * When the calling component's GeoJSON first resolves or swaps to an error,
 * it will update `FitToGeojson` which will then check if all registered
 * components are settled.
 *
 * If all results are settled, it will fit the content within the map view.
 *
 */
export const useFitToGeojson = (geojsonResult: GeojsonResult) => {
  const context = useContext(FitToGeojsonContext);

  const { register, unregister, update } = context ?? {};

  const { id, geojson, isError } = geojsonResult;

  useLayoutEffect(() => {
    if (!register || !unregister) return;

    register(id);

    return () => {
      unregister(id);
    };
  }, [id, register, unregister]);

  useEffect(() => {
    if (!update) return;

    update({ geojson, id, isError });
  }, [geojson, id, isError, update]);
};
